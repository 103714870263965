<template>
  <!-- element:dialog START -->
  <el-dialog
    class="without-wrapper"
    :visible.sync="dialogVisible"
    :before-close="dialogClose"
    :fullscreen="true"
    :append-to-body="true"
    width="80%"
  >
    <span
      :class="[
        { 'screensaver-help' : type === 'manual' }
      ]"
      slot="title"
      v-if="title"
      v-html="title"></span>
    <h2
      v-if="headline"
      v-html="headline"
      :class="[
        { 'screensaver-help' : type === 'manual' }
      ]"></h2>
    <div
      :class="[
        'description',
        { 'screensaver-help' : type === 'manual' }
      ]"
      v-if="description"
      v-html="description"></div>
    <div
      class="description description-static"
      v-if="!description && type === 'manual'"
    >
      <!-- manual:steps START -->
      <div class="steps">
        <!-- manual:steps:step START
        <div class="step">
          <div class="text">
            <p v-html="`
              <span class='number'>1</span>
              ${$t('terminal.index.dialog.manual.step.first.headline')}
            `"></p>
          </div>
          <div class="image">
            <img
              src="@/assets/images/index-manual-step-1@3x.png"
              :alt="$t('terminal.index.dialog.manual.step.first.headline')"
            />
          </div>
        </div>
        manual:steps:step END -->

        <!-- manual:steps:step START
        <div class="step">
          <div class="text">
            <p v-html="`
              <span class='number'>2</span>
              ${$t('terminal.index.dialog.manual.step.second.headline')}
            `"></p>
          </div>
          <div class="image">
            <img
              src="@/assets/images/index-manual-step-2@3x.png"
              :alt="$t('terminal.index.dialog.manual.step.second.headline')"
            />
          </div>
        </div>
        manual:steps:step END -->

        <!-- manual:steps:step START -->
        <div class="step">
          <div class="text">
            <p v-html="`
              <span class='number'>1</span>
              ${$t('terminal.index.dialog.manual.step.third.headline')}
            `"></p>
          </div>
          <div class="image">
            <img
            src="@/assets/images/index-manual-step-3@3x.png"
            :alt="$t('terminal.index.dialog.manual.step.third.headline')"
          />
          </div>
        </div>
        <!-- manual:steps:step END -->

        <!-- manual:steps:step START -->
        <div class="step">
          <div class="text">
            <p v-html="`
              <span class='number'>2</span>
              ${$t('terminal.index.dialog.manual.step.fourth.headline')}
            `"></p>
          </div>
          <div class="image">
            <img
              src="@/assets/images/index-manual-step-4@3x.png"
              :alt="$t('terminal.index.dialog.manual.step.fourth.headline')"
            />
          </div>
        </div>
        <!-- manual:steps:step END -->

        <!-- manual:steps:step START -->
        <div class="step">
          <div class="text">
            <p v-html="`
              <span class='number'>3</span>
              ${$t('terminal.index.dialog.manual.step.fifth.headline')}
            `"></p>
          </div>
          <div class="image">
            <img
              src="@/assets/images/index-manual-step-5@3x.png"
              :alt="$t('terminal.index.dialog.manual.step.fifth.headline')"
            />
          </div>
        </div>
        <!-- manual:steps:step END -->
      </div>
      <!-- manual:steps END -->

      <div
        class="close-screensaver-help"
        v-if="type === 'manual'"
        v-on:click="dialogClose"
      ></div>
    </div>
  </el-dialog>
  <!-- element:dialog END -->
</template>

<script>
export default {
  name: 'dialog-default',
  props: {
    title: {
      type: String,
    },

    headline: {
      type: String,
    },

    description: {
      type: String,
    },

    dialogVisible: {
      type: Boolean,
      default: false,
    },

    dialogClose: {
      type: Function,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      dialogVisibleChild: null,
    };
  },
  mounted() {
    this.dialogClose();
  },
};
</script>
